import { faEnvelope, faGlobe, faGraduationCap, faMapPin, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Animated } from 'react-animated-css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { paths } from '../../../config';
import Strings from '../../../helper/Strings';
import { setAboutVisibility, setPageNumber } from '../../../redux/actions';
import './About.scss';

type AboutProps = {
	setPageNumber: any;
	pageNumber: number;
	previousPageNumber: number;
	language: string;
	setAboutVisibility: any;
	visible: boolean;
	location: any;
};
type AboutState = {
	removeModal: boolean;
	opacity: number;
};

/**
 * A modal like component which displays information about the person
 */
class About extends React.PureComponent<AboutProps, AboutState> {
	removeModalTimeout: any;

	constructor(props: AboutProps) {
		super(props);

		this.state = {
			removeModal: true,
			opacity: 0,
		};
	}

	componentWillMount() {
		this.updatesModal(this.props.pageNumber);
	}

	componentWillReceiveProps(newProp: AboutProps) {
		if (this.props.pageNumber !== newProp.pageNumber && newProp.pageNumber || newProp.location.pathname !== '/about') {
			this.updatesModal(newProp.pageNumber);
		}
	}

	updatesModal = pageNumber => {
		setTimeout(() => {
			if (pageNumber === 1 && paths.indexOf(this.props.location.pathname) === 1) {
				clearTimeout(this.removeModalTimeout);
				this.setState({ removeModal: false });
				this.props.setAboutVisibility(true);
				setTimeout(() => this.setState({ opacity: 1 }), 100);
			} else {
				this.props.setAboutVisibility(false);
				this.setState({ opacity: 0 });
				this.removeModal();
			}
		}, 0);
	};

	/**
	 * Dismisses the about component modal
	 */
	dismissModal = () => {
		if (!this.state.removeModal) {
			this.props.setAboutVisibility(false);
			this.setState({ opacity: 0 });

			let nextPageNumber = this.props.previousPageNumber;
			if (this.props.previousPageNumber === 1) {
				nextPageNumber = 0;
			}

			this.props.setPageNumber(nextPageNumber);
			this.removeModal();
		}
	};

	gotoProject = () => {
		this.props.setAboutVisibility(false);
		this.setState({ opacity: 0 });
		this.props.setPageNumber(2);
		this.removeModal();
	}

	removeModal = () => {
		this.removeModalTimeout = setTimeout(() => {
			this.setState({ removeModal: true });
		}, 500);
	}

	render() {
		const { opacity, removeModal } = this.state;
		const { pageNumber, language, visible } = this.props;
		const info = Strings(language).about;

		// Removes extra top margin for the first h1 in the markdown
		setTimeout(() => {
			let article = document.getElementById('projectlink');
			if (article) {
				article.onclick = this.gotoProject;
			}
		}, 1);

		return (
			<div className='about' style={{ display: !removeModal ? 'flex' : 'none' }}>
				<div className='margin' onClick={this.dismissModal} style={{ opacity }} />
				<Animated animationIn='zoomIn' animationOut='zoomOut' isVisible={visible}>
					<div className={'modal ' + (!opacity && window.innerWidth > 700 ? 'bubble' : '')} style={{maxWidth: language === 'en' ? 650 : 700}}>
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignContent: 'center',
								maxHeight: language === 'en' ? 610 : 660,
								padding: 25,
								width: 'calc(100% - 50px)',
							}}>
							<div onClick={this.dismissModal} className='close' style={{ opacity }}>
								<FontAwesomeIcon icon={faTimes} size='sm' />
							</div>
							<div className='content' style={{ opacity }}>
								<div className='inner-content'>
									<div className='row'>
										<FontAwesomeIcon icon={faMapPin} size='lg' />
										<div className='text'>
											<p>{info.location}</p>
										</div>
									</div>
									<div className='row'>
										<div className='text'>
											<p>{info.education[0]}</p>
											<p>{info.education[1]}</p>
											<p>{info.education[2]}</p>
										</div>
										<FontAwesomeIcon icon={faGraduationCap} size='lg' />
									</div>
									<div className='row'>
										<FontAwesomeIcon icon={faGlobe} size='lg' />
										<div className='text'>
											<p>{info.languages}</p>
										</div>
									</div>
								</div>
								<p style={{lineHeight: 1.7}} dangerouslySetInnerHTML={{__html: info.description}} />
								<a className='row' href={'mailto:' + info.email} style={{marginTop: 20}}>
									<div className='text'>
										<p>{info.email}</p>
									</div>
									<FontAwesomeIcon icon={faEnvelope} size='lg' />
								</a>
							</div>
						</div>
					</div>
				</Animated>
			</div>
		);
	}
}

const mapState = state => {
	return {
		pageNumber: state.StateReducer.page,
		previousPageNumber: state.StateReducer.previousPage,
		language: state.StateReducer.language,
		visible: state.AboutReducer.aboutVisible
	};
};

const mapDispatch = dispatch => {
	return bindActionCreators({ setPageNumber, setAboutVisibility }, dispatch);
};

export default withRouter(
	connect(
		mapState,
		mapDispatch
	)(About)
);
