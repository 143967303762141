import { createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import { createBlacklistFilter } from 'redux-persist-transform-filter';
import storage from 'redux-persist/lib/storage';
import rootReducer from '../reducers';

// you want to remove some keys before you save
const saveSubsetProjectBlacklistFilter = createBlacklistFilter('ProjectReducer', ['readmeVisible', 'readmeLink', 'clickedLink']);
const saveSubsetAboutBlacklistFilter = createBlacklistFilter('AboutReducer', ['aboutVisible']);
const saveSubsetStateBlacklistFilter = createBlacklistFilter('StateReducer', ['path', 'prevPath']);

const persistConfig = {
	key: 'root',
	storage,
	keyPrefix: '',
	transforms: [saveSubsetProjectBlacklistFilter, saveSubsetAboutBlacklistFilter, saveSubsetStateBlacklistFilter],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export let store = createStore(
	persistedReducer,
	(<any>window).__REDUX_DEVTOOLS_EXTENSION__ && (<any>window).__REDUX_DEVTOOLS_EXTENSION__()
);
export let persistor = persistStore(store);
