import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { paths } from '../config';
import { getGithubInfo } from '../helper/project';
import { setPageNumber, setProjectData, setProjectLanguages, setPath, setPrevPath } from '../redux/actions';
import './App.scss';
import { LanguageSwitch } from './language_switch';
import { Navigation } from './navigation';
import { About, Home, Project } from './sections';
import ProgLanguageSwitch from './sections/project/ProgLanguageSwitch';
import ProjectReadme from './sections/project/ProjectReadme';
const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);

type AppProps = {
	setLanguage: any;
	language: string;
	location: any;
	pageNumber: number;
	setPageNumber: any;
	history: any;
	setProjectData: any;
	setProjectLanguages: any;
	setPath: any;
	setPrevPath: any;
	previousPageNumber: number;
	hasProjectInfo: boolean;
	fetchedAt: string;
	readmeVisible: boolean;
	path: string;
	prevPath: string;
};
type AppState = {
	action: string;
}

/**
 * The root component which handles every components and the logic of paths
 */
class App extends React.PureComponent<AppProps, AppState> {
	divRef: any;

	constructor(props: AppProps) {
		super(props);

		this.state = {
			action: props.history.action,
		};
	}

	componentDidMount() {
		document.addEventListener('scroll', this.trackScrolling);

		let diffHours = moment.range(moment(this.props.fetchedAt), moment()).diff('hours');

		if (!this.props.hasProjectInfo || !this.props.fetchedAt || diffHours >= 24) {
			getGithubInfo().then(({ data, languages }) => {
				this.props.setProjectData(data);
				this.props.setProjectLanguages(languages);
				this.trackScrolling();
			});
		}

		// Updates the page number with the pathname when the site loads
		this.updatePageNumber(this.getPathPageNumber(this.props.location.pathname));
		this.props.setPath(this.props.location.pathname);

		// Removes the loading element when the page is first loaded
		const ele = document.getElementById('loader');
		if (ele) {
			ele.classList.add('available');
			setTimeout(() => {
				ele.outerHTML = '';
			}, 500);
		}

		window.addEventListener('resize', this.resize);
		this.resize();
	}

	componentWillUnmount() {
		document.removeEventListener('scroll', this.trackScrolling);
		window.removeEventListener('resize', this.resize);
	}

	componentWillReceiveProps(newProp: AppProps) {
		let pathsChanged = newProp.location.pathname !== this.props.path;
		let pageNumberChanged = newProp.pageNumber !== this.props.pageNumber;
		let goingBack = paths[newProp.pageNumber] === this.props.prevPath;
		
		if (pageNumberChanged && pathsChanged) {
			this.props.setPrevPath(this.props.path);
			this.props.setPath(newProp.location.pathname);
		} else {
			if (pageNumberChanged && (!goingBack || this.state.action !== 'PUSH')) {
				this.props.history.push(paths[newProp.pageNumber]);
				this.setState({action: 'PUSH'})
			}
			if (pathsChanged && !pageNumberChanged) {
				this.props.setPrevPath(this.props.path);
				this.props.setPath(newProp.location.pathname);
				this.updatePageNumber(paths.indexOf(newProp.location.pathname));
			}
		}

		// console.log(goingBack, pageNumberChanged, pathsChanged, newProp.location.pathname, this.props.path, this.props.prevPath, newProp.history.action, this.state.action, this.state.backClicked)
		if (goingBack && pageNumberChanged && this.state.action !== 'POP' && newProp.history.action !== 'POP') {
			newProp.history.goBack();
			this.setState({action: 'POP'});
		}
	}

	resize = () => {
		this.trackScrolling();
	};

	isBottom(el: Element) {
		return el.getBoundingClientRect().bottom - 30 <= window.innerHeight;
	}

	trackScrolling = () => {
		const wrappedElement = document.getElementsByClassName('app')[0];
		let element = document.getElementById('special');
		if (element) {
			if (this.isBottom(wrappedElement)) {
				element.classList.remove('overlay');
			} else {
				element.classList.add('overlay');
			}
		}
	};

	/**
	 * Updates the page number in redux if it has changed
	 *
	 * @param pageNumber The page number to be saved in redux
	 */
	updatePageNumber = (pageNumber: number) => {
		if (this.props.pageNumber !== pageNumber) {
			this.props.setPageNumber(pageNumber);
		}
	};

	/**
	 * Returns the page number value of the passed pathname
	 *
	 * @param path The URL path
	 */
	getPathPageNumber = (path: string) => {
		return paths.indexOf(path);
	};

	/**
	 * Renders the appropriate page according to the pageNumber
	 */
	pageRenderer = () => {
		let renderPage = this.props.pageNumber;

		if (renderPage === 1) {
			if (this.props.previousPageNumber !== renderPage) {
				renderPage = this.props.previousPageNumber;
			} else {
				renderPage = 0;
			}
		}

		switch (renderPage) {
			case 0: {
				return <Home />;
			}
			case 2: {
				return <Project />;
			}
		}
	};

	render() {
		const { pageNumber, previousPageNumber } = this.props;

		return (
			<div className='app'>
				<div className='bg' />
				{pageNumber === 2 || (previousPageNumber === 2 && pageNumber === 1) ? (
					<ProgLanguageSwitch
						className='blur'
						style={{
							filter: pageNumber === 1 || this.props.readmeVisible ? 'blur(4px)' : 'blur(0px)',
						}}
					/>
				) : null}

				<div
					className='blur'
					style={{ filter: pageNumber === 1 || this.props.readmeVisible ? 'blur(4px)' : 'blur(0px)' }}>
					{this.pageRenderer()}
				</div>

				<About />
				<ProjectReadme />

				<div
					id='special'
					ref={this.divRef}
					className={'blur ' + (pageNumber === 2 || (previousPageNumber === 2 && pageNumber === 1) ? 'overlay' : '')}
					style={{
						filter: pageNumber === 1 || this.props.readmeVisible ? 'blur(4px)' : 'blur(0px)',
						position: 'fixed',
						width: '100%',
						height: '50px',
						bottom: 0,
						zIndex: 150,
						transition: 'all 0.5s ease'
					}}>
					<Navigation />
					<LanguageSwitch />
				</div>
			</div>
		);
	}
}

const mapState = state => {
	return {
		language: state.StateReducer.language,
		pageNumber: state.StateReducer.page,
		previousPageNumber: state.StateReducer.previousPage,
		hasProjectInfo: state.ProjectReducer.languages.length !== 0 && state.ProjectReducer.data.length !== 0,
		fetchedAt: state.ProjectReducer.fetchedAt,
		readmeVisible: state.ProjectReducer.readmeVisible,
		path: state.StateReducer.path,
		prevPath: state.StateReducer.prevPath,
	};
};

const mapDispatch = dispatch => {
	return bindActionCreators({ setPageNumber, setProjectData, setProjectLanguages, setPath, setPrevPath }, dispatch);
};

export default withRouter(
	connect(
		mapState,
		mapDispatch
	)(App)
);
