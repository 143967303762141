export default [
    {
        name: 'Robobat',
        link: '',
        description: `Code for the boat of the uOttawa's Robotic club (Ottabotics), made with ROS`,
        languages: ['Python', 'CMake'],
        year: '2017',
        readmeLink: '',
        type: 'gitlab'
    }
]