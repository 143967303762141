import { faFacebook, faGithub, faStackOverflow, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Animated, AnimationString } from 'react-animated-css';
import { connect } from 'react-redux';
import ReactTextTransition, { presets } from 'react-text-transition';
import { bindActionCreators } from 'redux';
import Strings from '../../../helper/Strings';
import { setPageNumber } from '../../../redux/actions';
import './Home.scss';

type HomeProps = {
	setPageNumber: any;
	language: string;
};
type HomeState = {
	showBigBubble: boolean;
	bubbleMouse: boolean;
	bubbleContent: Array<any>;
	animationIn: AnimationString;
	animationOut: AnimationString;
	textIndex: number;
	isSmallWidth: boolean;
};

/**
 *
 */
class Home extends React.PureComponent<HomeProps, HomeState> {
	inter: any;

	constructor(props: HomeProps) {
		super(props);

		this.state = {
			showBigBubble: false,
			bubbleMouse: false,
			bubbleContent: [
				{ link: 'https://www.linkedin.com/in/benoit-jeaurond', icon: faLinkedinIn, ariaLabel: 'Check my LinkedIn page' },
				{ link: 'https://github.com/BenJeau', icon: faGithub, ariaLabel: 'Check my Github page' },
				{ link: 'https://stackoverflow.com/users/11006837/benoît-jeaurond?tab=profile', icon: faStackOverflow, ariaLabel: 'Check me on Stack Overflow' },
				{ link: 'https://www.facebook.com/ben.jeau', icon: faFacebook, ariaLabel: 'Check my Facebook' },
			],
			animationIn: 'fadeIn',
			animationOut: 'fadeOut',
			textIndex: 0,
			isSmallWidth: false,
		};
	}

	componentDidMount() {
		this.animate();
		window.addEventListener('resize', this.resize);
		this.resize();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.resize);
		window.clearInterval(this.inter);
	}

	resize = () => {
		if (window.innerWidth > 500) {
			if (this.state.isSmallWidth) this.setState({ isSmallWidth: false });
		} else {
			if (!this.state.isSmallWidth) this.setState({ isSmallWidth: true });
		}
	};

	bubbleOnMouseOver = () => {
		this.setState({ bubbleMouse: true });
		setTimeout(() => this.setState({ showBigBubble: true }), 300);
	};

	bubbleOnMouseLeave = () => {
		this.setState({ bubbleMouse: false });
		setTimeout(() => this.setState({ showBigBubble: false }), 300);
	};

	animate = () => {
		this.inter = window.setInterval(() => {
			this.setState({
				textIndex: this.state.textIndex + 1,
			});

			if (this.state.textIndex % 4 === 3) {
				window.clearInterval(this.inter);
				window.setTimeout(() => {
					this.animate();
				}, 2000);
			}
		}, 1500);
	};

	render() {
		const { showBigBubble, bubbleMouse, bubbleContent, animationOut, animationIn, isSmallWidth, textIndex } = this.state;
		const { language } = this.props;
		const info = Strings(language).home;

		return (
			<div className='home'>
				<div className='picture-container'>
					<Animated animationIn={animationIn} animationOut={animationOut} isVisible>
						<div
							className='picture'
							onClick={() => {
								this.props.setPageNumber(1);
							}} />
					</Animated>

					<Animated animationIn={animationIn} animationOut={animationOut} isVisible>
						<div className='bubble' onMouseOver={this.bubbleOnMouseOver} onMouseLeave={this.bubbleOnMouseLeave}>
							<Animated
								animationIn={animationIn}
								animationOut={animationOut}
								isVisible={(showBigBubble && bubbleMouse) || isSmallWidth}
								style={{ display: (showBigBubble && bubbleMouse) || isSmallWidth ? 'flex' : 'none' }}
								className='social'>
								{bubbleContent.map(({ link, icon, ariaLabel }, key) => (
									<a href={link} key={key} target='_blank' rel='noopener noreferrer' aria-label={ariaLabel}>
										<FontAwesomeIcon icon={icon} size='lg' className='icon' />
									</a>
								))}
							</Animated>

							<Animated
								animationIn={animationIn}
								animationOut={animationOut}
								isVisible={!((showBigBubble && bubbleMouse) || isSmallWidth)}
								style={{ display: !((showBigBubble && bubbleMouse) || isSmallWidth) ? 'flex' : 'none' }}>
								<FontAwesomeIcon icon={faShareAlt} size='lg' />
							</Animated>
						</div>
					</Animated>

					<h1>Benoît Jeaurond</h1>

					<ReactTextTransition
						text={info.description[textIndex % info.description.length]}
						style={{ margin: '0 4px 0 0' }}
						spring={presets.wobbly}
						inline
						overflow
					/>
				</div>
			</div>
		);
	}
}

const mapState = state => {
	return {
		language: state.StateReducer.language,
	};
};

const mapDispatch = dispatch => {
	return bindActionCreators({ setPageNumber }, dispatch);
};

export default connect(
	mapState,
	mapDispatch
)(Home);
