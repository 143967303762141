import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Animated } from 'react-animated-css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setLanguage } from '../../redux/actions';
import './LanguageSwitch.scss';

type LanguageSwitchProps = {
	language: string;
	setLanguage: any;
};
type LanguageSwitchState = {
	hover: boolean;
};

/**
 * The language switcher component located at the bottom rigth of the webpage to toggle the language
 * on the website between french and english. It is represented by the globe and displays the language
 * to be switched when the mouse hovers the component.
 */
class LanguageSwitch extends React.PureComponent<LanguageSwitchProps, LanguageSwitchState> {
	constructor(props: LanguageSwitchProps) {
		super(props);

		this.state = {
			hover: false,
		};
	}

	/**
	 * Changes the language in redux to the opposite language
	 */
	changeLanguage = () => {
		this.props.setLanguage(this.getOppositeLanguage());
	};

	/**
	 * Returns the opposite language, french if the website is in english, and vice-versa
	 */
	getOppositeLanguage = () => {
		return this.props.language === 'en' ? 'fr' : 'en';
	};

	render() {
		return (
			<div className='language-switch'>
				<a
					onClick={this.changeLanguage}
					onMouseEnter={() => this.setState({ hover: true })}
					onMouseLeave={() => this.setState({ hover: false })}>
					<Animated animationIn='fadeIn' animationOut='fadeOut' isVisible={this.state.hover}>
						<p>{this.getOppositeLanguage()}</p>
					</Animated>
					<FontAwesomeIcon icon={faGlobeAmericas} size='lg' className='icon' />
				</a>
			</div>
		);
	}
}

const mapState = state => {
	return {
		language: state.StateReducer.language,
	};
};

const mapDispatch = dispatch => {
	return bindActionCreators({ setLanguage }, dispatch);
};

export default connect(
	mapState,
	mapDispatch
)(LanguageSwitch);
