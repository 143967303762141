import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { Animated } from 'react-animated-css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setClickedProjectLink, setProjectReadmeLink, setProjectReadmeVisibility } from '../../../redux/actions';
import ProgLanguageSwitch from './ProgLanguageSwitch';
import './Project.scss';
import ProjectCard from './ProjectCard';
import otherRepos from '../../../otherRepos';

type ProjectProps = {
	data: any;
	selectedLanguage: string;
	languages: string[];
	setProjectReadmeVisibility: any;
	setProjectReadmeLink: any;
	setClickedProjectLink: any;
};

/**
 * The project screen component which displays every project that are on github
 * and the other ones which are hard coded
 */
class Project extends React.PureComponent<ProjectProps> {
	render() {
		const { data } = this.props;
		const selectedLang = ['All', ...this.props.languages][this.props.selectedLanguage];

		let hasData = data.length !== 0;
		let noData = hasData ? {} : {
			paddingLeft: 10,
			width: 'calc(100vw - 20px)'
		};

		return (
			<div className='project' style={noData}>
				<Animated
					animationIn='fadeIn'
					animationOut='fadeOut'
					isVisible={hasData}
					style={{ display: hasData ? 'flex' : 'none' }}>
					<section className='project-cards'>
						<ReactCSSTransitionGroup
							transitionName='project-card-anim'
							transitionEnterTimeout={500}
							transitionLeaveTimeout={300}>
							{[...data, ...otherRepos]
								.filter((i: any) => {
									return selectedLang === 'All' || i.languages.includes(selectedLang);
								})
								.map((i: any, key: number) => {
									return (
										<ProjectCard
											onClick={() => {
												this.props.setProjectReadmeLink(i.readmeLink);
												this.props.setClickedProjectLink(i.link);

												if (i.readmeLink) {
													this.props.setProjectReadmeVisibility(true);
												}
											}}
											key={key}
											name={i.name}
											description={i.description}
											year={i.year}
											languages={i.languages}
											link={i.link}
											readmeLink={i.readmeLink}
											type={i.type}
										/>
									);
								})}
						</ReactCSSTransitionGroup>
					</section>
				</Animated>

				<Animated
					animationIn='fadeIn'
					animationOut='fadeOut'
					isVisible={!hasData}
					style={{
						display: !hasData ? 'flex' : 'none',
						position: 'relative',
						alignItems: 'center',
						justifyContent: 'center',
						flexDirection: 'column',
						width: 'calc(100% - 25px)',
					}}>
					<div className='lds-ripple' style={{paddingBottom: 10}}>
						<div />
						<div />
					</div>
					<p>Fetching GitHub data</p>
				</Animated>
			</div>
		);
	}
}

const mapState = state => {
	return {
		data: state.ProjectReducer.data,
		languages: state.ProjectReducer.languages,
		selectedLanguage: state.ProjectReducer.selectedLanguage,
	};
};

const mapDispatch = dispatch => {
	return bindActionCreators({ setProjectReadmeVisibility, setProjectReadmeLink, setClickedProjectLink }, dispatch);
};

export default connect(
	mapState,
	mapDispatch
)(Project);
