import { combineReducers } from 'redux';
import AboutReducer from './AboutReducer';
import ProjectReducer from './ProjectReducer';
import StateReducer from './StateReducer';

export default combineReducers({
	StateReducer,
	ProjectReducer,
	AboutReducer,
});
