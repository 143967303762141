import {
	SET_ABOUT_VISIBILITY,
} from './constants';

let defaultState = {
	aboutVisible: false,
};

export default function ProjectReducer(state = defaultState, action) {
	switch (action.type) {
		case SET_ABOUT_VISIBILITY:
			return {
				...state,
				aboutVisible: action.aboutVisible,
			};

		default:
			return state;
	}
}
