import {
	SET_ABOUT_VISIBILITY,
	SET_CLICKED_PROJECT_LINK,
	SET_LANGUAGE,
	SET_PAGE,
	SET_PROJECT_DATA,
	SET_PROJECT_LANGUAGES,
	SET_PROJECT_README_LINK,
	SET_PROJECT_README_VISIBILITY,
	SET_PROJECT_SELECTED_LANGUAGE,
	SET_PATH,
	SET_PREV_PATH
} from '../reducers/constants';

/**
 * Updates the language of the website
 *
 * @param language The two letter language abbreviation to change the website's language
 */
const setLanguage = (language: string) => {
	return {
		type: SET_LANGUAGE,
		language,
	};
};

/**
 * Updates the navigation of the website
 *
 * @param page The number representing the page number of the webstie
 */
const setPageNumber = (page: number) => {
	return {
		type: SET_PAGE,
		page,
	};
};

/**
 * Saves the github projects data when opening the website
 *
 * @param data The data about github projects
 */
const setProjectData = (data: any[]) => {
	return {
		type: SET_PROJECT_DATA,
		data,
	};
};

/**
 * Saves the github projects languages when opening the website
 *
 * @param languages The languages data about github projects
 */
const setProjectLanguages = (languages: any[]) => {
	return {
		type: SET_PROJECT_LANGUAGES,
		languages,
	};
};

/**
 * Saves the index of the selected programming language in the project component
 *
 * @param selectedLanguage The index of the project languages array that is selected
 */
const setProjectSelectedLanguage = (selectedLanguage: number) => {
	return {
		type: SET_PROJECT_SELECTED_LANGUAGE,
		selectedLanguage,
	};
};

const setProjectReadmeLink = (readmeLink: string) => {
	return {
		type: SET_PROJECT_README_LINK,
		readmeLink,
	};
};

const setProjectReadmeVisibility = (readmeVisible: boolean) => {
	return {
		type: SET_PROJECT_README_VISIBILITY,
		readmeVisible,
	};
};

const setClickedProjectLink = (clickedLink: string) => {
	return {
		type: SET_CLICKED_PROJECT_LINK,
		clickedLink,
	};
};


const setAboutVisibility = (aboutVisible: boolean) => {
	return {
		type: SET_ABOUT_VISIBILITY,
		aboutVisible,
	};
};

const setPath = (path: string) => {
	return {
		type: SET_PATH,
		path,
	};
};

const setPrevPath = (prevPath: string) => {
	return {
		type: SET_PREV_PATH,
		prevPath,
	};
};

export {
	setLanguage,
	setPageNumber,
	setProjectData,
	setProjectLanguages,
	setProjectSelectedLanguage,
	setProjectReadmeVisibility,
	setProjectReadmeLink,
	setClickedProjectLink,
	setAboutVisibility,
	setPath,
	setPrevPath
};
