import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Strings from '../../helper/Strings';
import { setPageNumber } from '../../redux/actions';
import './Navigation.scss';

type NavigationProps = {
	pageNumber: number;
	language: string;
	setPageNumber: any;
};

/**
 * The bottom center navigation to modify the navigation of the website. It modifies the
 * state of the navigation in the redux reducer.
 */
class Navigation extends React.PureComponent<NavigationProps> {
	render() {
		const { pageNumber, language } = this.props;
		const info = Strings(language).navigation;

		return (
			<div className='nav'>
				<ul>
					{info.pages.map((page: string, key: number) => (
						<li key={key}>
							<a
								className={key === pageNumber ? 'selected' : ''}
								onClick={() => {
									if (this.props.pageNumber !== key) {
										this.props.setPageNumber(key);
									}
								}}>
								{page}
							</a>
						</li>
					))}
				</ul>
			</div>
		);
	}
}

const mapState = state => {
	return {
		language: state.StateReducer.language,
		pageNumber: state.StateReducer.page,
	};
};

const mapDispatch = dispatch => {
	return bindActionCreators({ setPageNumber }, dispatch);
};

export default connect(
	mapState,
	mapDispatch
)(Navigation);
