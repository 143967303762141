import React from 'react';
import { GitHub, Gitlab, Lock } from 'react-feather';
import './ProjectCard.scss';

type ProjectCardProps = {
	name: string;
	description: string;
	languages: Array<string>;
	link: string;
	year: string;
	onClick: any;
	type: string;
	readmeLink: string;
};
type ProjectCardState = {
	opacity: number;
	hasLink: boolean;
};

/**
 * The cards displayed in the project component containing information about github repositories
 * and personal projects
 */
class ProjectCard extends React.PureComponent<ProjectCardProps, ProjectCardState> {
	componentWillReceiveProps(newProps: ProjectCardProps) {
		this.updateLink(newProps);
	}

	componentWillMount() {
		this.updateLink(this.props);
		this.setState({
			opacity: Math.random() / 4 + 0.75,
		});
	}

	updateLink = (props: ProjectCardProps) => {
		let hasLink = true;

		try {
			new URL(props.link);
		} catch(e) {
			hasLink = false;
		}

		this.setState({
			hasLink
		});
	}

	render() {
		const { opacity, hasLink } = this.state;
		const { name, description, languages, link, year, onClick, type, readmeLink } = this.props;

		return (
			<div className={'project-card ' + (readmeLink ? 'public' : '')} style={{ backgroundColor: 'rgba(35, 35, 35, ' + opacity + ')' }} onClick={onClick}>
				<div className='content'>
					<div className='ext'>
						<p className='title'>{name}</p>
						<p className='year'>{year}</p>
					</div>
					<div className='middle'>
						<p className='description'>{description}</p>
					</div>
					<div className='ext'>
						<p className='languages'>{languages.join(', ')}</p>
						<div className={'icon ' + (link ? 'public' : '')} onClick={(e) => {
							if (link) {
								e.stopPropagation();
								let win = window.open(link, '_blank');
								if (win) win.focus();
							}
						}} >
							{
								type && type === 'gitlab' ? <Gitlab /> : <GitHub />
							}
							
							<div style={{position: "absolute", bottom: -7, right: -7}}>
								{ 
									!hasLink ? <Lock size={13} color={"#cccccc"}/> : null 
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ProjectCard;