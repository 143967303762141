import { githubUsername, languageBlacklist, repoBlacklist } from '../config';
import { getEvents, getRepoInfo, getRepoLang, getRepos, getUser } from './github-api';

const getGithubInfo = async (): Promise<any> => {
	let userInfo = await getUser(githubUsername);

	if (userInfo === null) {
		return;
	}

	let repos: string[] = [];

	// Adds information about the last 300 events
	let json = (await getEvents(githubUsername, { per_page: 100, page: 1 })) as any;

	if (json !== null) {
		json.map((data: any) => {
			let repoName = data.repo.name;
			if (data.type === 'PushEvent' && !repos.includes(repoName)) {
				repos.push(repoName);
			}
		});
	}

	// Adds the repos from public repos
	let publicRepos = (await getRepos(githubUsername, {
		per_page: 100,
		page: 1,
	})) as any;
	let publicNames = publicRepos.reduce((acc: string[], i: any) => {
		acc.push(i.full_name);
		return acc;
	}, []);
	repos.push(...publicNames);

	repos = repos.filter(i => !repoBlacklist.includes(i));

	// Removes duplicates
	repos = Array.from(new Set(repos));

	let repoInfo: any = [];
	await Promise.all(
		repos.map(async repo => {

			let info = await getRepoInfo(repo);
			let lang = await getRepoLang(repo);

			if (info && lang) {
				repoInfo.push({
					info,
					lang
				});
			}
		})
	);

	let data: any[] = [];
	repoInfo.sort((a, b) => (a.info.created_at < b.info.created_at ? 1 : a.info.created_at > b.info.created_at ? -1 : 0));
	repoInfo.map(({ info, lang }, key: number) => {
		data.push({
			name: info.name,
			link: info.html_url,
			description: info.description,
			languages: Object.keys(lang).slice(0, 4),
			year: info.created_at.substr(0, 4),
			readmeLink: `https://raw.githubusercontent.com/${info.full_name}/${info.default_branch}/README.md`,
			type: 'github',
		});
	});

	let f = data.reduce((acc: string[], i: any) => {
		acc.push(...i.languages);
		return acc;
	}, []);

	let p: any = [];
	f.forEach(i => {
		if (!languageBlacklist.includes(i)) p.push(i);
	});

	return {
		data,
		languages: Array.from(new Set(p)).sort(),
	};
};

export { getGithubInfo };
