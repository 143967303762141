import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import marked from 'marked';
import React from 'react';
import { Animated } from 'react-animated-css';
import { Link } from 'react-feather';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setProjectReadmeLink, setProjectReadmeVisibility } from '../../../redux/actions';
import './ProjectReadme.scss';
import parse from 'html-react-parser';


type ProjectReadmeProps = {
	readmeLink: string;
	visible: boolean;
	setProjectReadmeVisibility: any;
	setProjectReadmeLink: any;
	clickedLink: string;
};
type ProjectReadmeState = {
	markdown: any;
	exists: boolean;
	loading: boolean;
	removeModal: boolean;
	opacity: number;
};

class ProjectReadme extends React.PureComponent<ProjectReadmeProps, ProjectReadmeState> {
	readmeModalTimeout: any;

	constructor(props: ProjectReadmeProps) {
		super(props);

		this.state = {
			markdown: '',
			exists: true,
			loading: true,
			removeModal: true,
			opacity: 0,
		};
	}

	componentWillReceiveProps(newProps: ProjectReadmeProps) {
		if (newProps.readmeLink) {
			this.getMarkdown(newProps.readmeLink);
		}

		if ('visible' in newProps && newProps.visible) {
			clearTimeout(this.readmeModalTimeout);
			this.setState({ removeModal: false });
			setTimeout(() => this.setState({ opacity: 1 }), 100);
		}
	}

	componentDidMount() {
		if (this.props.readmeLink != '') {
			this.getMarkdown(this.props.readmeLink);
		}
	}

	getMarkdown = async (link: string) => {
		let isValidLink = true;

		try {
			new URL(link);
		} catch (e) {
			isValidLink = false;
		}

		if (link) {
			if (isValidLink) {
				this.setState({ loading: true });
				let response = await fetch(link);
				if (response.status === 404) {
					this.setState({
						exists: false,
						loading: false,
					});
				} else {
					let markdown = marked(await response.text());
					let wrapper = document.createElement('div');
					wrapper.innerHTML = markdown;
					if (wrapper) {
						let images = (wrapper as HTMLElement).getElementsByTagName('img');
						Array.from(images).forEach(image => {
							if (image.src.includes(window.location.hostname)) {
								let url = new URL(image.src);
								image.src = link.substr(0, link.length - 10) + url.pathname;
							}
						});

						let links = (wrapper as HTMLElement).getElementsByTagName('a');
						Array.from(links).forEach(href => {
							href.target = '_blank';
							href.rel = 'noopener noreferrer';
							if (href.href.includes(window.location.hostname)) {
								let url = new URL(href.href);
								let repo = new URL(link);

								if (url.pathname.includes('png')) {
									href.href = link.substr(0, link.length - 10) + url.pathname;
								} else {
									href.href = 'https://www.github.com/' + repo.pathname.split('/')[1] + '/' + repo.pathname.split('/')[2] + '/blob/' + repo.pathname.split('/')[3] + url.pathname;
								}
							}
						});
					}

					this.setState({
						markdown: wrapper.innerHTML,
						exists: true,
						loading: false,
					});
				}
			} else {
				this.setState({
					markdown: link,
					exists: true,
					loading: false,
				});
			}
		}
	};

	dismissReadme = () => {
		this.readmeModalTimeout = setTimeout(() => {
			this.setState({
				removeModal: true,
				loading: true,
				exists: true,
				markdown: ''
			});
		}, 500);
		this.setState({ opacity: 0 });
		this.props.setProjectReadmeVisibility(false);
		this.props.setProjectReadmeLink('');
	};

	render() {
		const { visible } = this.props;
		const { markdown, exists, loading, removeModal, opacity } = this.state;

		// Removes extra top margin for the first h1 in the markdown
		setTimeout(() => {
			let article = document.getElementsByTagName('article')[0];
			if (article && article.children[0] && article.children[0].tagName === 'H1') {
				(article.children[0] as HTMLElement).style.marginTop = '0px';
			}
		}, 1);

		return (
			<div className='project-readme' style={{ display: !removeModal ? 'flex' : 'none' }}>
				<div className='margin' onClick={this.dismissReadme} style={{ opacity }} />
				<Animated animationIn='zoomIn' animationOut='zoomOut' isVisible={visible}>
					<div className={'modal' + (exists ? '' : ' not-found') + (loading ? ' loading' : '')}>
						<div className='close' onClick={this.dismissReadme}>
							<FontAwesomeIcon icon={faTimes} size='sm' />
						</div>
						<div>
							<Animated animationIn='fadeIn' animationOut='zoomOut' isVisible={!loading && exists}> <article>{parse(markdown)}</article> </Animated>

							<Animated animationIn='fadeIn' animationOut='fadeOut' isVisible={!exists && !loading} style={{display: !exists && !loading ? 'flex' : 'none'}}>
								<div>
									<p>
										No README file is present in the selected project, but you can still see the content of the project by clicking{' '}
										<a href={this.props.clickedLink} target='_blank' rel='noopener noreferrer'>
											here
										</a>
									</p>
								</div>
							</Animated>

							<Animated animationIn='fadeIn' animationOut='fadeOut' isVisible={loading}  style={{display: loading ? 'flex' : 'none'}}>
								<div className="lds-ripple">
									<div></div>
									<div></div>
								</div>
							</Animated>
							{this.props.clickedLink && exists ? <a className='link' href={this.props.clickedLink} target='_blank' rel='noopener noreferrer'>
								<Link />
							</a> : null}
						</div>
					</div>
				</Animated>
			</div>
		);
	}
}

const mapState = state => {
	return {
		visible: state.ProjectReducer.readmeVisible,
		readmeLink: state.ProjectReducer.readmeLink,
		clickedLink: state.ProjectReducer.clickedLink,
	};
};

const mapDispatch = dispatch => {
	return bindActionCreators({ setProjectReadmeVisibility, setProjectReadmeLink }, dispatch);
};

export default connect(
	mapState,
	mapDispatch
)(ProjectReadme);
