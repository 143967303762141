export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_PAGE = 'SET_PAGE';
export const SET_PATH = 'SET_PATH';
export const SET_PREV_PATH = 'SET_PREV_PATH';
export const SET_PROJECT_DATA = 'SET_PROJECT_DATA';
export const SET_PROJECT_LANGUAGES = 'SET_PROJECT_LANGUAGES';
export const SET_PROJECT_SELECTED_LANGUAGE = 'SET_PROJECT_SELECTED_LANGUAGE';
export const SET_PROJECT_README_LINK = 'SET_PROJECT_README_LINK';
export const SET_PROJECT_README_VISIBILITY = 'SET_PROJECT_README_VISIBILITY';
export const SET_CLICKED_PROJECT_LINK = 'SET_CLICKED_PROJECT_LINK';
export const SET_ABOUT_VISIBILITY = 'SET_ABOUT_VISIBILITY';