import { SET_LANGUAGE, SET_PAGE, SET_PATH, SET_PREV_PATH } from './constants';

let defaultState = {
	language: 'en',
	page: 0,
	previousPage: undefined,
	path: undefined,
	prevPath: undefined
};

export default function StateReducer(state = defaultState, action) {
	switch (action.type) {
		case SET_LANGUAGE:
			return {
				...state,
				language: action.language,
			};
		
		case SET_PATH:
			return {
				...state,
				path: action.path,
			};
		
		case SET_PREV_PATH:
			return {
				...state,
				prevPath: action.prevPath,
			};

		case SET_PAGE:
			let previousPage: any = state.page;

			return {
				...state,
				previousPage,
				page: action.page,
			};

		default:
			return state;
	}
}
