type QueryType = {
	[key: string]: string | number;
};

/**
 * Helper method for the GitHub API calls
 *
 * @param path The GitHub api URL path
 * @param params An object containing query parameters to be appended to the URL
 * @return A promise which returns an object with the API call data
 */
const apiHelper = async (path: string, params?: QueryType): Promise<Object> => {
	let query = '';

	if (params !== undefined) {
		query = getQueryParam(params);
	}

	return fetch(`https://api.github.com/${path}` + query, {
		headers: {
			Authorization: 'token b909c8da3237591e62dc6935d951fec1ffd24248',
		},
	}).then(res => {
		// Gets header response
		let link = res.headers.get('Link');
		if (link !== null) {
			// Parses the link header response
			let t = link.split(',').map(i =>
				i.split(';').map((i, key) => {
					key === 0 ? (i = i.slice(1, -1)) : (i = i.split('"')[1]);
					return i.trim();
				})
			);

			let object = {} as any;
			Object.assign(object, ...t.map(([v, k]) => ({ [k]: v })));

			// Makes another API call with the next page
			if ('next' in object) {
				return apiHelper(path, { per_page: 100, page: object.next.substr(-1) });
			}
		}

		if (res.ok) {
			return res.json();
		} else {
			return null;
		}
	}).catch(e => console.log(e));
};

/**
 * Returns a string that is appendable to a URL
 *
 * @param query Query parameter object to be appended to the URL
 * @param params An object containing query parameters to be appended to the URL
 * @returns A parameter string to be appended to a URL
 */
const getQueryParam = (query: QueryType): string => {
	let text = '?';
	let keys = Object.keys(query);

	for (let i = 0; i < keys.length; i++) {
		text += keys[i] + '=' + query[keys[i]] + '&';
	}

	return text.substr(0, text.length - 1);
};

/**
 * Returns an object with information ausersbout the user
 * https://developer.github.com/v3//#get-a-single-user
 *
 * @param user The GitHub username to be used for the API call
 * @param params An object containing query parameters to be appended to the URL
 * @return A promise which returns an object with the user's data
 */
const getUser = (user: string, params?: QueryType) => {
	return apiHelper(`users/${user}`, params);
};

/**
 * Returns an object with information about the user's repositories
 * https://developer.github.com/v3/repos/#list-user-repositories
 *
 * @param user The GitHub username to be used for the API call
 * @param params An object containing query parameters to be appended to the URL
 * @return A promise which returns an object with the user's repositories data
 */
const getRepos = (user: string, params?: QueryType) => {
	return apiHelper(`users/${user}/repos`, params);
};

/**
 * Returns an object with information about the user's events
 * https://developer.github.com/v3/activity/events/#list-events-performed-by-a-user
 *
 * @param user The GitHub username to be used for the API call
 * @param params An object containing query parameters to be appended to the URL
 * @return A promise which returns an object with the user's event data
 */
const getEvents = (user: string, params?: QueryType) => {
	return apiHelper(`users/${user}/events`, params);
};

/**
 * Returns an object with information about the user's events
 * https://developer.github.com/v3/repos/commits/#list-commits-on-a-repository
 *
 * @param fullName The GitHub username/repo to be used for the API call
 * @param params An object containing query parameters to be appended to the URL
 * @return A promise which returns an object with the repo's commits data
 */
const getRepoCommits = (fullName: string, params?: QueryType) => {
	return apiHelper(`repos/${fullName}/commits`, params);
};

/**
 * Returns an object with information about a repo
 * https://developer.github.com/v3/repos/#get
 *
 * @param fullName The GitHub username/repo to be used for the API call
 * @param params An object containing query parameters to be appended to the URL
 * @return A promise which returns an object with the repo's data
 */
const getRepoInfo = (fullName: string, params?: QueryType) => {
	return apiHelper(`repos/${fullName}`, params);
};

/**
 * Returns an object with information about the repo's programming languages
 * https://developer.github.com/v3/repos/#list-languages
 *
 * @param fullName The GitHub username/repo to be used for the API call
 * @param params An object containing query parameters to be appended to the URL
 * @return A promise which returns an object with the repo's programming languages data
 */
const getRepoLang = (fullName: string, params?: QueryType) => {
	return apiHelper(`repos/${fullName}/languages`);
};

export { getUser, getRepos, getEvents, getRepoCommits, getRepoInfo, getRepoLang };
