import {
	SET_CLICKED_PROJECT_LINK,
	SET_PROJECT_DATA,
	SET_PROJECT_LANGUAGES,
	SET_PROJECT_README_LINK,
	SET_PROJECT_README_VISIBILITY,
	SET_PROJECT_SELECTED_LANGUAGE,
} from './constants';

let defaultState = {
	data: [],
	languages: [],
	selectedLanguage: 0,
	readmeVisible: false,
	readmeLink: '',
	clickedLink: '',
};

export default function ProjectReducer(state = defaultState, action) {
	switch (action.type) {
		case SET_PROJECT_DATA:
			return {
				...state,
				data: action.data,
				fetchedAt: new Date().toJSON(),
			};

		case SET_PROJECT_LANGUAGES:
			return {
				...state,
				languages: action.languages,
			};

		case SET_PROJECT_SELECTED_LANGUAGE:
			return {
				...state,
				selectedLanguage: action.selectedLanguage,
			};

		case SET_PROJECT_README_VISIBILITY:
			return {
				...state,
				readmeVisible: action.readmeVisible,
			};

		case SET_PROJECT_README_LINK:
			return {
				...state,
				readmeLink: action.readmeLink,
			};

		case SET_CLICKED_PROJECT_LINK:
			return {
				...state,
				clickedLink: action.clickedLink,
			};

		default:
			return state;
	}
}
