import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Strings from '../../../helper/Strings';
import { setProjectSelectedLanguage } from '../../../redux/actions';
import './ProgLanguageSwitch.scss';
import { Animated } from 'react-animated-css';

type PropLanguageProps = {
	language: string;
	languages: string[];
	setProjectSelectedLanguage: any;
	selected: number;
	style: any;
	className: string;
};

/**
 * The component on top of the project section allowing to filter the list of projects
 * by the programming language
 */
class ProgLanguageSwitch extends React.Component<PropLanguageProps> {
	render() {
		const { language, style, className } = this.props;
		const info = Strings(language).project;

		return (
			<div className={`prog-language ${className}`} style={{display: this.props.languages.length === 0 ? 'none' : 'flex'}}>
				<Animated
					animationIn='fadeIn'
					animationOut='fadeOut'
					isVisible={this.props.languages.length !== 0}
					style={style}>
					<ReactCSSTransitionGroup
						transitionName='project-lang-anim'
						transitionEnterTimeout={500}
						transitionLeaveTimeout={300}>
						{[info.all, ...this.props.languages].map((i, key) => (
							<div
								key={key}
								onMouseDown={() => this.props.setProjectSelectedLanguage(key)}
								className={this.props.selected === key ? 'selected' : ''}>
								<p>{i}</p>
							</div>
						))}
					</ReactCSSTransitionGroup>

					<div className='description'>
						<p dangerouslySetInnerHTML={{__html: info.description}} />
					</div>
				</Animated>
			</div>
		);
	}
}

const mapState = state => {
	return {
		languages: state.ProjectReducer.languages,
		selected: state.ProjectReducer.selectedLanguage,
		language: state.StateReducer.language,
	};
};

const mapDispatch = dispatch => {
	return bindActionCreators({ setProjectSelectedLanguage }, dispatch);
};

export default connect(
	mapState,
	mapDispatch
)(ProgLanguageSwitch);
